const url = "https://node-sniperbot.mobiloitte.io"; // staging
// const url = "http://172.16.1.217:1984"; // local
// const url = "https://node.mbaacademy.asia"; // liveurl

const ApiConfig = {
  login: `${url}/api/v1/user/login`,
  signup: `${url}/api/v1/user/register`,
  verify: `${url}/api/v1/user/verify`,
  forgot: `${url}/api/v1/user/forgot`,
  changePassword: `${url}/api/v1/user/changePassword`,
  resend: `${url}/api/v1/user/resend`,
  profile: `${url}/api/v1/user/profile`,
  editProfile: `${url}/api/v1/user/editProfile`,
  uploadImage: `${url}/api/v1/upload/uploadImage`,
  kycApply: `${url}/api/v1/user/kycApply`,
  viewKyc: `${url}/api/v1/user/viewKyc`,
  planlist: `${url}/api/v1/plan/list`,
  updatePassword: `${url}/api/v1/user/updatePassword`,
  myWallets: `${url}/api/v1/user/myWallets`,
  deposit: `${url}/api/v1/user/deposit`,
  history: `${url}/api/v1/user/history`,
  withdraw: `${url}/api/v1/user/withdraw`,
  genOtp: `${url}/api/v1/user/genOtp`,
  fsfs: `${url}/api/v1/user/buy/`,
  activePlan: `${url}/api/v1/user/activePlan`,
};
export default ApiConfig;
