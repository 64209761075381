import React from "react";
import {
  Box,
  Container,
  Typography,
  makeStyles,
  ListItem,
  List,
  Grid,
} from "@material-ui/core";
import { Link as RouterLink ,useHistory} from "react-router-dom";
import Logo from "src/component/Logo";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    padding: "20px 50px",
    zIndex: "2",
    overflow: " hidden",
    background: theme.palette.background.head,
    [theme.breakpoints.down("md")]: {
      padding: "20px 0px",
    },
  },
  informationBox: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      justifyContent: "end",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  ul: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "0",
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: "400",
    textDecoration: "none",
    paddingLeft: "20px",
    paddingRight: "0px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      justifyContent: "start",
      paddingLeft: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      justifyContent: "start",
      paddingLeft: "10px",
    },
    "&:hover": {
      color: "#FF6600",
      textDecoration: "none",
      "& svg": {
        color: "red",
        fontSize: "15px",
      },
    },
  },
  logoContainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  footerText: {
    color: "#fff",
    textAlign: "end",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
const history = useHistory()
  return (
    <Box className={classes.footerSection}>
      <Container maxWidth={false}>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={5} md={3} className={classes.logoContainer}>
            <Logo
            onClick={()=>history.push("/")}
              style={{ width: "100%", maxWidth: "102px", cursor: "pointer" }}
            />
          </Grid>
          <Grid item xs={12} sm={7} md={6} className={classes.informationBox}>
            <List className={classes.ul}>
              <ListItem className={classes.link}>Overview</ListItem>
              <ListItem className={classes.link}>Features</ListItem>
              <ListItem className={classes.link}>Pricing</ListItem>
              <ListItem className={classes.link}>Careers</ListItem>
              <ListItem className={classes.link}>Help</ListItem>
              <ListItem
                to="/privacy-policy"
                component={RouterLink}
                className={classes.link}
              >
                Privacy
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Typography variant="body1" className={classes.footerText}>
              © 2023-2024 Arbitrage Exchange
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
