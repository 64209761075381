import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Box,
  Typography,
  Container, Avatar,
} from "@material-ui/core";
import Scroll from "react-scroll";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import { AuthContext } from "src/context/Auth";
const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    padding: " 15px 0px",
    "& .MuiButton-containedPrimary:hover": {
      color: "#fff",
    },
    "& .MuiButton-text": {
      padding: "6px 0px",
    },
    "& .profileButtonDiv": {
      display: "flex",
      gap: "24px"
    }
  },
  logoDrawer: {
    width: "140px",
  },
  drawerContainer: {
    padding: "20px 0px 20px 20px",
    height: "100%",
    background: theme.palette.background.header,
    color: "#fff",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    marginLeft: "0px !important",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      width: "80px !important",
    },
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",

      width: "75px",
    },
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "6px 0px",
  },

  menuButton1: {
    paddingLeft: "0",
    display: "block",
    color: "#fff",
    width: "100%",
    // minWidth: "100px",
    whiteSpace: "pre",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  menuMobile1: {
    padding: "15px 0",
    "& h4": {
      fontSize: "14px !important",
      lineHeight: " 17px",
      color: theme.palette.text.main,
      margin: "0 8px",
      fontWeight: "400",
      [theme.breakpoints.only("xs")]: {
        fontSize: "12px !important",
      },
    },
    "& svg": {
      color: theme.palette.text.main,
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  headerBox: {
    background: "linear-gradient(90deg, #0B0E11 0%, #FCD535 100%)",
    padding: "0px 50px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 0px",
    },
  },
  ecoButton: {
    whiteSpace: "pre",
    borderRadius: "2px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    border: "1px solid #fff",


  },
  ecoButton1: {
    whiteSpace: "pre",
    borderRadius: "32px",
    background: "#0B0E11",
    boxShadow: "3px 5px 4px 0px rgba(0, 0, 0, 0.25)",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    border: "none",
    height: "43px",

  },
  avatarmain: {
    gap: "8px"
  },
  name: {
    color: "#0B0E11",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "100%"
  },
  avatar:{
    "& .MuiAvatar-img":{
      width:"32px",
      height:"32px",
      borderRadius:"100%"
    }

  }
}));

export default function Header() {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const {
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    mainHeader,
    ecoButton,
    ecoButton1,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);
  const userData = auth.userData;
  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {femmecubatorLogo}
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" justifyContent="flex-end" alignItems="center" style={{gap:"30px"}}>
            <ScrollLink
              onClick={() => history.push("/")}
              smooth={true}
              duration={500}
              to="feature"
              style={{ cursor: "pointer" }}
            >
              <Typography variant="h6" className={menuButton1}>
                Features
              </Typography>
            </ScrollLink>
            <ScrollLink
              onClick={() => history.push("/")}
              smooth={true}
              duration={500}
              to="work"
              style={{ cursor: "pointer" }}
            >
              <Typography variant="h6" className={menuButton1}>
                How it works
              </Typography>
            </ScrollLink>
            <ScrollLink
              onClick={() => history.push("/")}
              smooth={true}
              duration={500}
              to="price"
              style={{ cursor: "pointer" }}
            >
              <Typography variant="h6" className={menuButton1}>
                Pricing
              </Typography>
            </ScrollLink>
            <ScrollLink
              onClick={() => history.push("/")}
              smooth={true}
              duration={500}
              to="/"
              style={{ cursor: "pointer" }}
            >
              <Typography variant="h6" className={menuButton1}>
                Affiliate Plan
              </Typography>
              
            </ScrollLink>
            <ScrollLink
              onClick={() => history.push("/")}
              smooth={true}
              duration={500}
              to="/"
              style={{ cursor: "pointer" }}
            >
              <Typography variant="h6" className={menuButton1}>
               Download
              </Typography>
              
            </ScrollLink>
          </Box>

        </Box>
        <Box className="profileButtonDiv">
          {auth.userLoggedIn &&
            <Box display="flex" alignItems="center"
              className={classes.avatarmain}
              onClick={() => history.push("/profile")}
              style={{ cursor: "pointer" }}
            >
              <Avatar src={
                userData?.profilePic
                  ? userData?.profilePic
                  : "images/editprofile.png"
              } className={classes.avatar} />
              <Typography variant="h6" className={classes.name}>
                sachin beniwal
              </Typography>
              <Box style={{width: 1,
height: 24,background: "#56CCF2"}}></Box>
            </Box>}
          <Box>
            {auth.userLoggedIn ? (
              <Button
                variant="outlined"
                className={ecoButton1}
                to="/my-wallet"
                color="primary"
                fullWidth
                component={Link}
              >
                Dashboard
              </Button>
            ) : (
              <Button
                variant="outlined"
                className={ecoButton1}
                to="/login"
                color="primary"
                fullWidth
                component={Link}
              >
                Get Started
              </Button>

            )} </Box>




        </Box>
      </Toolbar>
    );
  };
  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    //mobile
    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            {femmecubatorLogo}
            <ScrollLink
              onClick={() => history.push("/")}
              smooth={true}
              duration={500}
              to="feature"
              style={{ cursor: "pointer", textAlign: "left" }}
            >
              <Typography
                variant="h6"
                className={menuButton1}
                style={{ textAlign: "left", marginTop: "10px" }}
              >
                Features
              </Typography>
            </ScrollLink>
            <ScrollLink
              onClick={() => history.push("/")}
              smooth={true}
              duration={500}
              to="work"
              style={{ cursor: "pointer" }}
            >
              <Typography
                variant="h6"
                className={menuButton1}
                style={{ textAlign: "left", marginTop: "10px" }}
              >
                How it works
              </Typography>
            </ScrollLink>
            <ScrollLink
              onClick={() => history.push("/")}
              smooth={true}
              duration={500}
              to="price"
              style={{ cursor: "pointer" }}
            >
              <Typography
                variant="h6"
                className={menuButton1}
                style={{ textAlign: "left", marginTop: "10px" }}
              >
                Pricing
              </Typography>
            </ScrollLink>
            <ScrollLink
              onClick={() => history.push("/")}
              smooth={true}
              duration={500}
              to="/"
              style={{ cursor: "pointer" }}
            >
              <Typography
                variant="h6"
                className={menuButton1}
                style={{ textAlign: "left", marginTop: "10px" }}
              >
                Affiliate Plan
              </Typography>
            </ScrollLink>
            <Box style={{ marginTop: "10px" }}>
              {auth.userLoggedIn ? (
                <Button
                  variant="outlined"
                  className={ecoButton}
                  to="/my-wallet"
                  color="primary"
                  fullWidth
                  component={Link}
                >
                  Dashboard
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  className={ecoButton}
                  to="/login"
                  color="primary"
                  fullWidth
                  component={Link}
                >
                  Get Started
                </Button>
              )}
            </Box>
            <div></div>
          </div>
        </Drawer>

        <Box display="flex" justifyContent="space-between">
          {femmecubatorLogo}
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            className={drawericon}
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon
              width="60px"
              height="60px"
              style={{ color: "#F39200", fontSize: "26px" }}
            />
          </IconButton>
        </Box>
      </Toolbar>
    );
  };
  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );
  return (
    <>
      <AppBar
        className={classes.headerBox}
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ border: "none" }}
      >
        <Container maxWidth={false}>
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </>
  );
}
